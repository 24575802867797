import { useNavigate } from "react-router-dom";
import presentImage from "../../Assets/Images/present_image.png";
import "./Rules.scss";
import logo from "../../Assets/Images/ЛОГОТИП.png";
import bot from "../../Assets/Images/bot.png";
import { useResize } from "../../Hooks/useResize";

const Rules = () => {
  const navigate = useNavigate();
  const size = useResize()

  return (
    <div className="WrapperRules">
      <div className="RulesHeader">
        <div className="headerDescription">
          {" "}{size.width >1024?
        
        <h1>Правила тестирования</h1>:
        
        <h2>Правила тестирования</h2>}
          <span>
            Представьте себе идеальную жизнь. Ту, что приносит максимальное
            счастье и полную удовлетворенность. Какая она? Организуйте себе свою
            мечту.
          </span>
        </div>

        <div className="botBox">
          <div onClick={() => window.open("https://telegram.me/boxtestQA_bot")}>
            Задать вопрос
          </div>
          <img
            className="botLogo"
            alt=""
            src={bot}
            onClick={() => window.open("https://telegram.me/boxtestQA_bot")}
          ></img>
          <img alt="" src={logo}></img>
        </div>
      </div>
      <div className="RulesBox">
        <div className="RuleContent">
          <h2>1. Исходный ресурс</h2>
          <span>
            У вас есть <b>100 единиц уникального универсального ресурса</b>,
            который может буквально все. Больше нет, но что особенно важно —{" "}
            <b>и не будет.</b>
          </span>
        </div>
        <div className="RuleContent">
          <h2>2. Что нужно сделать?</h2>{" "}
          <span>
            <b>Распределите</b> этот ресурс по ячейкам так,{" "}
            <b>как считаете нужным</b>. Сколько будет вложено в качество,{" "}
            <b>настолько оно будет организовано</b>.
          </span>
        </div>
        <div className="RuleContent">
          <h2>3. Сколько ячеек?</h2>{" "}
          <span>
            11 групп, в каждой по 4 качества и дополнительное поле для прочего.
            Если чего-то важного для вас нет в ячейках,{" "}
            <b>оставьте ресурс незадействованным</b>.
          </span>
        </div>
        <div className="RuleContent">
          <h2>4. Что важно помнить?</h2>{" "}
          <span>
            <b>Вы инвестируете в то</b>, без чего ваша жизнь{" "}
            <b>не будет качественной</b>. <b>Никаких ограничений</b> — все так,
            как Вам понимается, хочется или кажется.
          </span>
        </div>
      </div>{" "}
      <div className="wrapperBottomBox">
        <button
          className="btnActive btnActiveBig"
          onClick={() => navigate("/TokenData")}
        >
          Начать тестирование
        </button>
        <div className="mapBox">
          <div className="stepBox">
            <div>шаг</div>
            <div className="currentStep">1</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="currentStep">2</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="stepNumber">3</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="stepNumber">4</div>
          </div>
          <div className="stepLine"></div>
          <div>
            <img src={presentImage} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
