const dataText = {
    '1': 'Возможности —то, как человек оценивает свои устремления любой природы в части существующих и/или применимых к нему ограничений.',
    '2': 'Лидерство — стремление и потенциалы человека направлять и вести за собой других; в конечном итоге это разновидность стремления к власти.',
    '3': 'Образование — основа для личностного развития, включающая в себя знания, умения и навыки; результаты комбинации обучения и воспитания.',
    '4': 'Признание — положительная и выражаемая реакция окружения на достигнутые человеком результаты в любых (всяких) областях жизни.',
    '5': 'Прочее — другие доступные причины, которые могут мотивировать человека к действиям (реактивным или проактивным — неважно).',
    '6': 'Быт — отсутствие серьезных причин для беспокойства в повседневной жизни.',
    '7': 'Имущество — уверенность в том, что ваше имущество будет сохранено в обычных условиях.',
    '8': 'Личность — убежденность в наличии надежных механизмов защиты от личных угроз.',
    '9': 'Общество — уверенность в том, что общественная среда останется стабильной на длительное время.',
    '10': 'Прочее — понимание того, что в других аспектах также нет существенных рисков.',
    '11': 'Близкие — отсутствие тревог за близких людей, которые важны для вас больше всего.',
    '12': 'Ламинарность — ощущение нормальности происходящего: устойчивая и спокойная ситуация «день за днём».',
    '13': 'Окружающая среда — приемлемая экологическая и социальная среда вокруг.',
    '14': 'Социум — отсутствие видимых поводов для тревоги в окружающем социуме.',
    '15': 'Прочее — другие аспекты, которые могут, но не вызывают беспокойство.',
    '16': 'Выбор — удовлетворенность разнообразием и формами досуга.',
    '17': 'Доступность — отсутствие препятствий, включая финансовые, с реализации выбора.',
    '18': 'Зрелища — наличие в доступном досуге любых развлечений визуального характера, имеющих не только познавательный характер.',
    '19': 'Общение — удовлетворение от общения, взаимодействия или коммуникации с другими людьми в подходящих для этого формах.',
    '20': 'Прочее — другие формы отдыха, заменяющих рутинные или обязательные занятия (такие, как отдых на природе, пляжный туризм, чтение, размышления и др.).',
    '21': 'Бог — высшая сила или сущность, в которое верят люди; это источник добра, мира, уклада, норм жизни и поведения, иррациональный источник помощи в трудные моменты.',
    '22': 'Вера — уверенность в том, чего нельзя увидеть или доказать, но что человек считает своими жизненными маяками; помогает справляться с трудностями, даёт надежду.',
    '23': 'Нравственность — понимание того, что хорошо и плохо, правильное поведение и отношение к другим людям. Это внутренняя «руководящая звезда», которая помогает без специального анализа принимать решения, которые можно считать «правильными».',
    '24': 'Община — группы людей, объединённых общими интересами, верованиями,  целями, чувствами. Члены общин поддерживают друг друга, чувствуя себя частью чего—то большего.',
    '25': 'Прочее — всё, что не попадает под конкретные категории духовности, что—то иное, дополнительное или отличное, имеющее значение в качестве тонких материй (мотивов).',
    '26': 'Востребованность — ощущение того, что основные Ваши усилия как—то и кем—то из окружения, но обязательно востребованы.',
    '27': 'Предпринимательство — готовность вести свою деятельность, принимая риски возможного неуспеха как естественную часть жизни.',
    '28': 'Работа — наличие деятельности, которая потребляет ресурсы человека (время, силы, эмоции, …), имеет цель и приносит вознаграждение.',
    '29': 'Хобби — занятия, которыми люди занимаются лишь по собственному желанию, и увлечения, доставляющие радость; но не обязательно отвлекающие от повседневности.',
    '30': 'Прочее — другие формы регулярной активности, важные для вашего образа жизни и/или постоянно присутствующие в Вашей жизни.',
    '31': 'Образ жизни — организация своей жизни с минимальным вредом для здоровья.',
    '32': 'Психо—эмоциональная сфера — ощущения, обеспечивающие чувство комфорта и равновесия в психологическом плане, а также создающие эмоциональную гармонию.',
    '33': 'Сервис — доступность медицинских услуг и практик, а также достаточность присутствующей базы для поддержания здоровья.',
    '34': 'Физическое здоровье — вся базовая функциональная и биологическая основа вашего организма.',
    '35': 'Прочее — другие аспекты, связанные с поддержкой статуса здорового человека.',
    '36': 'Контакты — удовлетворение от библиотеки участников коммуникаций, форм и доступности соответствующих взаимоотношений, обмена информацией и/или обеспечиваемыми услугами.',
    '37': 'Контент — достаточность наполнения содержания сообщений, доступных в процессах и каналах коммуникации, удовлетворённость охватом и качеством.',
    '38': 'Повестка – полнота, актуальность и разнообразие cобытийной картинки, используемой людьми для обязательного сопровождения обыденных потоков своей жизни.',
    '39': 'Сеть — вся среда активного общения, которая охватывает разные формы и типы взаимодействий, отличающихся от прямых и адресных контактных практик.  коммуникации.',
    '40': 'Прочее — иные элементы, влияющие на аспект коммуникаций, помимо названных. ',
    '41': '«Без долгов» — состояние, когда человек условно никому ничего не должен и может организовывать свою жизнь, свободно распоряжаться своими средствами.',
    '42': 'Богатство — наличие запасов денежных (легко переводимых в денежные) ресурсов с превышением текущих потребностей (независимо от их источника).',
    '43': 'Доходы — деньги, которые человек получает в текущем режиме в порядке вознаграждения за свою ежедневную/регулярную активность.',
    '44': 'Запасы — ресурсы, сберегаемые человеком с определёнными целями или «на чёрный день»',
    '45': 'Прочее — другие важные материальные аспекты, кроме уже приведённых.',
    '46': 'Враги — те, кто подозреваются в причинении вреда, мешают в достижении успеха, жизненных целей, препятствуют взаимовыгодным отношениям.',
    '47': 'Друзья — люди, с которыми у нас крепкие и близкие отношения (дружба).',
    '48': 'Семья — самые близкие люди, с которыми нас сввязывают глубокие отношения и поддержка, взаимоинтересы, родственные связи, духовная/материальная близость.',
    '49': 'Соседи — люди, с которыми человек рядом живёт, непосредственно и часто контактирует и/или взаимодействует (даже вынужденно), с кем разделяет эффекты социальной среды в части совместной материально—психологической базы.',
    '50': 'Прочие — иные люди (связи) в жизни человека, прямо не попавшие в состав 4—х явных категорий окружения, но событийно или явленчески важные (например — коллеги, соратники, одноклубники, и т.д.).',
    '51': 'Авторитет — уровень значения, уважения и признания, получаемого человеком по умолчанию в социальных группах, которые являются его целевыми стратами.',
    '52': 'Мобильность — осознание всего комплекса своих возможностей для переезда, изменения жизненного пути, карьеры, складывающегося состояния (исключая семейное).',
    '53': 'Реализация прав — способность пользоваться своими осознаваемыми правами без значительных ограничений, носящих несправедливый и/или некорректный характер',
    '54': 'Социальный — степень независимости и свободы от обязательств, кроме тех, что накладываются на человека его участием в социальных структурах обыденной жизни.',
    '55': 'Прочее — другие важные измерители положения человека в обществе, которые имеют для него значение (вне зависимости от того — удаётся их определить, или нет).',
    'Амбиции' : 'Амбиции —личные вызовы и цели, которые человек имеет в качестве своих жизненных ориентиров, в т.ч. оценивая возможности и способности в своей жизни; включая коммуникации с другими людьми и/или в социальных группах.',
    'Безопасность' : 'Безопасность —оценка степени стрессирования окружающей среды, связанной с поддержанием базового порядка и стабильности общества.',
    'Благополучие' : 'Благополучие —чувство удовлетворенности доступными благами, условиями жизни, действующими социальными нормами.',
    'Досуг' : 'Досуг — восприятие возможностей для проведения свободного времени, как для обеспечения полноты мировосприятия, так и для снятия симптомов напряжения.',
    'Духовное' : 'Духовность —стремление человека к внутренней гармонии, поискам смысла жизни, благородным и высоким мотивам поведения, разделений духовных ценностей на уровнях, сопоставимых с важностью материальных устремлений.',
    'Занятость' : 'Занятость — характеристика вашей практической деятельности, которая явно показывает вовлечённость в созидание и поддерживает чувство социальной плотности.',    
    'Здоровье' : 'Здоровье — значение оценки состояния здоровья и способности поддерживать его в текущих жизненных обстоятельтсвах.',    
    'Коммуникации' : 'Коммуникации — комплекс взаимодействия и обмена информацией, влияющий на весь спектр отношений между людьми (вкл. институты), и их социальные дистанции.',    
    'Материальное' : 'Материальное — ценимое в жизни и влияющее на стабильность и уверенность в будущем, но имеющее чаще всего лишь экономический смысл и/или денежное выражение.',    
    'Окружение' : 'Окружение — акторы (участники) социальной инфраструктуры (люди главным образом), через взаимодействие с которыми человек осознаёт и воспринимает сумму всю обстоятельств своей жизни.', 
    'Статус' : 'Статус — описание положения человека в обществе; то, как он представлен в социальном взаимодействии; то, что он учитывает во влиянии оценок общества',       
}

export default dataText