const prizeList = [
  {
    place: "Технопарк",
    prize: "Абонемент на 5 или 10 занятий в технопарк",
    url: "",
  },
  {
    place: "Допобразование",
    prize: "Специалист по работе с людьми с избыточной массой тела ",
    url: "https://idpo.rgsu.net/courses/spetsialist-po-rabote-s-lyudmi-s-izbytochnoy-massoy-tela/",
  },
  {
    place: "Допобразование",
    prize: "Физическая культура и спорт в системе общего образования",
    url: "https://idpo.rgsu.net/courses/fizicheskaya-kultura-i-sport-v-sisteme-obschego-obrazovaniya/",
  },
  {
    place: "Допобразование",
    prize: "Ландшафтное проектирование",
    url: "https://idpo.rgsu.net/courses/landshaftnoe-proektirovanie/",
  },
  {
    place: "Допобразование",
    prize: "Дальше-больше (английский язык для продвинутых: Pre-Intermediate)",
    url: "https://idpo.rgsu.net/courses/dalshe-bolshe-angliyskiy-yazyk-dlya-prodvinutyh-pre-intermediate/",
  },
  {
    place: "Допобразование",
    prize:
      "Преподавание информационных систем и технологий в высшем образовании",
    url: "https://idpo.rgsu.net/courses/prepodavanie-informatsionnyh-sistem-i-tehnologiy-v-vysshem-obrazovanii/",
  },
  {
    place: "Допобразование",
    prize: "Английский, французский, немецкий, испанский языки: I ступень",
    url: "https://idpo.rgsu.net/courses/angliyskiy-yazyk-i-stupen-frantsuzskiy-yazyk-i-stupen-nemetskiy-yazyk-i-stupen-ispanskiy-yazyk-i-stupen/",
  },
  {
    place: "Допобразование",
    prize: "Введение в защиту персональных данных",
    url: "https://idpo.rgsu.net/courses/vvedenie-v-zaschitu-personalnyh-dannyh/",
  },
  {
    place: "Допобразование",
    prize: "Живопись и композиция",
    url: "https://idpo.rgsu.net/courses/zhivopis/",
  },
  {
    place: "Допобразование",
    prize: "Основы нутрициологии",
    url: "https://idpo.rgsu.net/courses/osnovy-nutritsiologii/",
  },
];

export default prizeList;
