import React, { useEffect, useState, useRef } from "react";

import "./Selector.scss";

const Selector = ({
  selectorData,
  name,
  userFormData,
  setUserFormData,
  placeholder,
}) => {
  const [isSelector, setIsSelector] = useState(false);
  const selectorRef = useRef(null); // Create a ref for the selector

  const handleFormChange = (e, value) => {
    setUserFormData({
      ...userFormData,
      [e.target.id]: value,
    });
    setIsSelector(false);
  };

  const functionSelecotClick = () => {
    setIsSelector((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (selectorRef.current && !selectorRef.current.contains(event.target)) {
      setIsSelector(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="Selector" ref={selectorRef}>
      <div
        className="DataDefault"
        id={name}
        onClick={functionSelecotClick}
        style={{
          color: userFormData[name] === "" ? "rgba(0,0,0, .4)" : "#000",
        }}
      >
        {userFormData[name] === "" ? placeholder : userFormData[name]}
      </div>
      {isSelector && (
        <div className="wrapperListSelector">
          {selectorData.map((item, index) => (
            <div
              id={name}
              onClick={(e) => handleFormChange(e, item)}
              key={index}
              className="wrapperListSelectorItem"
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Selector;
