import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/ЛОГОТИП.png";
import bot from "../../Assets/Images/bot.png";
import Selector from "../../UI/Selector/Selector";
import presentImage from "../../Assets/Images/present_image.png";
import "./Registration.scss";
import { useResize } from "../../Hooks/useResize";

const Registration = () => {
  const navigate = useNavigate();
  const today = new Date();
  const year = today.getFullYear() - 14;
  const [wide, setWide] = useState(true);

  const size = useResize();

  const [popUpStatus, setPopUpStatus] = useState(false);

  const [userFormData, setUserFormData] = useState({
    gender: "",
    sphera: "",
    educationLevel: "",
    mlevel: "",
    birthYear: "",
  });
  const [isFormFilled, setIsFormFilled] = useState(false);

  const sphere = [
    "Без акцентов на специфику",
    "Безопасность и правоохранение",
    "Бизнес и предпринимательство",
    "Информационные технологии",
    "Медицина и здравоохранение",
    "Менеджмент и управление",
    "Педагогика и воспитание",
    "Образование и наука",
    "Промышленность и индустрия",
    "Связь и коммуникации",
    "Строительство и транспорт",
    "Торговля и сервисы",
    "Финансовые инструменты",
    "Сельское хозяйство",
  ];

  const educationLevel = [
    "Без образования",
    "Высшее образование — специалитет, магистратура",
    "Высшее образование — бакалавриат",
    "Два и более высших образования",
    "Среднее общее образование",
    "Ученая степень",
    "Среднее профессиональное образование",
  ];

  const mlevel = [
    "Достаток. Не испытываю затруднений, помогаю материально другим",
    "Бедность. Денег не хватает на продукты питания, лекарства, одежду",
    "Нуждаюсь. Денег хватает лишь на основные продукты питания и одежду",
    "Нищета. Живу в крайней нужде",
    "Стабильное. Особых материальных затруднений нет, но не все покупки по карману",
    "Устойчивое. Нет никаких материальных затруднений",
  ];

  useEffect(() => {
    const isFilled = Object.values(userFormData).every((value) => value !== "");
    setIsFormFilled(isFilled);
  }, [userFormData]);

  function onFinishClick() {
    localStorage.setItem("reg_info", JSON.stringify(userFormData));
    navigate("/Rules");
  }

  useEffect(() => {
    if (size.width < 1024) {
      setWide(false);
    } else {
      setWide(true);
    }
  }, [size]);

  return (
    <div className="WrapperCenterAnket">
      
        <div className="wrapperHeader">
          {size.width >1024?
          <h1>Исследование о качестве жизни</h1>:
          <h2>Исследование о качестве жизни</h2>}
          <div className="botBox">
            <div
              onClick={() => window.open("https://telegram.me/boxtestQA_bot")}
            >
              Задать вопрос
            </div>
            <img
              className="botLogo"
              alt=""
              src={bot}
              onClick={() => window.open("https://telegram.me/boxtestQA_bot")}
            ></img>
            <img alt="" src={logo}></img>
          </div>
        </div>
      

      <div className="WrapperAnket">
        <div className="mobileHeader">
          {wide ? (
            <h2>Расскажите немного о себе</h2>
          ) : (
              <h3>Расскажите немного о себе</h3>
           
          )}
        </div>

        <div className="SelectorBox">
          <Selector
            name="gender"
            placeholder="Пол"
            selectorData={["М", "Ж"]}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
          <Selector
            name="birthYear"
            placeholder="Год рождения"
            selectorData={Array.from({ length: 86 }, (_, i) => year - i)}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
        </div>
        <Selector
          name="sphera"
          placeholder=" Cфера деятельности"
          selectorData={sphere}
          userFormData={userFormData}
          setUserFormData={setUserFormData}
        />
        <Selector
          name="educationLevel"
          placeholder="Уровень образования"
          selectorData={educationLevel}
          userFormData={userFormData}
          setUserFormData={setUserFormData}
        />
        <Selector
          name="mlevel"
          placeholder="Материальное положение"
          selectorData={mlevel}
          userFormData={userFormData}
          setUserFormData={setUserFormData}
        />
        <div className="registrationBottomBox">
          <button
            className={
              isFormFilled
                ? "btnActive btnActiveBig"
                : "btnRegister btnActiveBig"
            }
            disabled={isFormFilled ? false : true}
            onClick={onFinishClick}
          >
            К правилам тестирования
          </button>
          <div className="mapBox">
            <div className="stepBox">
              <div>шаг</div>
              <div className="currentStep">1</div>
            </div>
            <div className="stepLine"></div>
            <div className="stepBox">
              <div>шаг</div>
              <div className="stepNumber">2</div>
            </div>
            <div className="stepLine"></div>
            <div className="stepBox">
              <div>шаг</div>
              <div className="stepNumber">3</div>
            </div>
            <div className="stepLine"></div>
            <div className="stepBox">
              <div>шаг</div>
              <div className="stepNumber">4</div>
            </div>
            <div className="stepLine"></div>
            <div>
              <img src={presentImage} alt=""></img>
            </div>
          </div>
        </div>
      </div>
      {popUpStatus && (
        <div className="popUp">
          <div className="popUpRegistration">
            <div className="popUpRegistrationContent">
              <div>
                Представьте себе <b>идеальную</b> жизнь.
              </div>
              <div>
                Ту, что приносит максимальное счастье и полную
                удовлетворённость. Какая она? Организуйте себе свою мечту.
              </div>
              <div>
                У вас есть 100 единиц уникального универсального <b>ресурса</b>,
                который может буквально всё.
              </div>
              <div>
                Больше нет; но что особенно важно - и <b>не будет</b>.
              </div>
              <div>
                <b>Распределите</b> этот ресурс по ячейкам так, как считаете
                нужным. Сколько куда-то будет вложено, <b>настолько</b> это и
                будет организовано.
              </div>
              <div>
                Помните - Вы <b>инвестируете</b> в то, без чего ваша жизнь не
                будет качественной.
              </div>
              <div>
                11 групп, в каждой по 4 качества и дополнительное поле для
                прочего. Если чего-то важного для вас нет в ячейках,{" "}
                <b>оставьте </b>
                ресурс незадействованным.
              </div>
              <div>
                Никаких <b>ограничений</b> - всё так, как Вам понимается,
                хочется или кажется.
              </div>
            </div>
            <Link
              className="btnActive"
              to={"TokenData"}
              state={{ from: userFormData }}
            >
              Начать
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Registration;
